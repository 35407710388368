<template>
  <skeleton theme="opacity" shape="radius" bg-color="#dcdbdc" class="p-3">
    <div class="row">
      <div class="col-12">
        <tb-skeleton :aspect-ratio="0.4"></tb-skeleton>
      </div>
    </div>
  </skeleton>
</template>

<script>
  export default {
    name: 'sk-card',
  };
</script>
