<template>
 <div class="container-fluid">
    <div class="row">
      <sidebar></sidebar>
      <nav-header></nav-header>
      <!-- MAIN CONTENT start -->
      <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10" >
          <breadcrumb :items="pageData.breadcrumb"></breadcrumb>

          <!-- Program -->
          <section class="program-section px-0 mx-0 px-xl-5 mx-xl-5  mt-0 mt-md-5 mb-3">
              <div class="container-fluid px-0 px-sm-3">
                  <!-- Program Description -->
                  <div class="row">
                      <div class="col">
                          <general-info @program-info-loaded="infoSuccessfullyLoaded" v-if="authenticated"></general-info>
                      </div>
                  </div>

                  <!-- program  subjects -->
                  <!-- <program-module-subjects :student-evaluations="programEvaluations" :program-module-subjects="programModuleSubjects"></program-module-subjects> -->


 <div v-for="data of modules">
 
 
     
 <!-- program subjects -->
      <div class="row mt-2 mb-md-4 mb-lg-0">
          <div class="col">
            <h3 class="text-center">
              <!-- {{ helpers.getLocalizedField(programModule, 'program_module') }} -->
              <template >{{data}}</template>
            </h3>
          </div>
      </div>


      
      <div class="row align-items-center justify-content-center mt-3 pb-5 mx-xl-5 px-xl-5">
        <div class="col">

      
      <div class="program  w-100 px-4">
          <table class="table table-blue-wide">
            <thead>
              <tr>
                  <th>{{ $t('my-program.subject-code') }}</th>
                  <th>{{ $t('my-program.subject') }}</th>
                  <th class="text-center">{{ $t('my-program.ects') }}</th>
                  <th class="text-center">{{ $t('my-program.semester') }}</th>
                  <th class="text-center" ></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(subjects,j) of  fullProgram.modulesWithSubjects[data]">
                <td :data-label="$t('my-program.subject-code')"> {{subjects.subject_code}}</td>
                <td :data-label="$t('my-program.subject')">
                  <a target="_blank"  :href="'http://online.ug.edu.ge/sillabus_system/syllabus_print.php?syllabusID='+subjects.syllabusID+'&lang=geo'">
                    {{ helpers.getLocalizedField(subjects, 'subject') }}
                  </a>
                </td>
                <td :data-label="$t('my-program.ects')" class="text-center">{{subjects.ECTS}}</td>
                <td :data-label="$t('my-program.semester')" class="text-center">{{subjects.subject_semester}}</td>
                <td  class="text-center"  >{{subjects.Eval}}</td>
              </tr>
            </tbody>
          </table>
      </div>
          </div>
      </div>
 


</div> 











  </div>
          </section>

      </main>
      <!-- MAIN CONTETN end -->
    </div>
  </div>
</template>

<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import generalInfo from '../components/program/general-info';
import { mapGetters } from 'vuex';
import ProgramModuleSubjects from '../components/program/program-module-subjects';
import navHeader from '../components/navbar/nav-header';
import breadcrumb from '../components/page/breadcrumb';


export default {
  name: 'my-program',
  components: { ProgramModuleSubjects, sidebar, appHeader, pageHeader, generalInfo,navHeader, breadcrumb },
data(){

  return{

      fullProgram:[],
      modules:[]

  }


},
  mounted(){

    this.$store.dispatch('program/getFullProgram').then(response=>{

        this.modules=Object.keys(response.data.modulesWithSubjects)
        this.fullProgram=response.data


    })


  },

  computed: {
    pageData() {
      return {
        title: this.$t('my-program.title'),
        breadcrumb: [{
          icon: '',
          title: this.$t('home.title'),
          link: '/',
          isActive: false,
        },
          {
          icon: '',
          title: this.$t('my-program.title'),
          link: '/my-program',
          isActive: true,
        }],
      };
    },
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/authenticated',
      programModuleSubjects: 'program/programModuleSubjects',
      programEvaluations: 'program/programEvaluations',
    })
  },

  methods: {
    infoSuccessfullyLoaded(info) {
      this.$store.dispatch('program/loadProgramEvaluations');
      for(let module of info.modules) { 

        this.$store.dispatch('program/loadProgramModuleSubjects', {
          programID: info.programID,
          moduleID: module.program_moduleID,
          concentrationID: module.pivot.concentrationID,
          concentration:module.pivot.concentration
        })
      }
    },



    







  }
};
</script>
<style lang="scss" scoped>
  .table-blue-wide td a {
    color: #557c84;
  }
</style>