import axios from 'axios';

export default class MinorProgramResource {


    getMinor(){

        return axios.get('/api/programs/minor')


    }




}