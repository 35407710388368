<template>
 
  <div v-if="programModule.subjects.filter((o)=>{return o.concentrationID==user.concentration}).length>0 ||  !user.concentration && programModule.subjects.length>0">
 
     
       <!-- program subjects -->
      <div class="row mt-2 mb-md-4 mb-lg-0">
          <div class="col">
            <h3 class="text-center">{{ helpers.getLocalizedField(programModule, 'program_module') }}
              <template v-if="programModule.pivot.concentration">- {{ programModule.pivot.concentration }} </template>
            </h3>
          </div>
      </div>
      <div class="row align-items-center justify-content-center mt-3 pb-5 mx-xl-5 px-xl-5">
        <div class="col">

            <!-- program main subjects -->
            <div class="program  w-100 px-4">
                <table class="table table-blue-wide">
                  <thead>
                    <tr>
                        <th>{{ $t('my-program.subject-code') }}</th>
                        <th>{{ $t('my-program.subject') }}</th>
                        <th class="text-center">{{ $t('my-program.ects') }}</th>
                        <th class="text-center">{{ $t('my-program.semester') }}</th>
                        <th class="text-center" v-if="studentEvaluations"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="subject of !user.concentration?programModule.subjects:programModule.subjects.filter((o)=>{return  o.concentrationID==user.concentration})">
                      <td :data-label="$t('my-program.subject-code')">{{ subject.subject_code }}</td>
                      <td :data-label="$t('my-program.subject')">
                        <a target="_blank" :href="'http://online.ug.edu.ge/sillabus_system/syllabus_print.php?syllabusID='+subject.syllabusID+'&lang=geo'">
                          {{ helpers.getLocalizedField(subject, 'subject') }}
                        </a>
                      </td>
                      <td :data-label="$t('my-program.ects')" class="text-center">{{ subject.ECTS }}</td>
                      <td :data-label="$t('my-program.semester')" class="text-center">{{ subject.semester }}</td>
                      <td  class="text-center" v-if="studentEvaluations">{{ getEvaluation(subject.subjectID) }}</td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </div>
      </div>

  
  

  </div>
  
</template>

<script>

 
import { mapGetters } from 'vuex';

  export default {
    name: 'program-module-subjects-table',
   
    computed: {
      ...mapGetters({ 
        user: 'auth/user'
      })
    },
  

    props: ['program-module', 'student-evaluations'],

    methods: {
      getEvaluation(subjectID) {
        const evaluation = this.studentEvaluations.filter(o => o.subjectID == subjectID)[0];

        if(evaluation) return evaluation.Eval;

        return '';
      }
    },
    
  };
</script>

<style lang="scss" scoped>
  .table-blue-wide td a {
    color: #557c84;
  }
</style>
