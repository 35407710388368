<template>
  <div>
 
  <div v-if="programModuleSubjects && !helpers.isEmptyObject(programModuleSubjects)">

    <div class="row" v-for="programModule of programModuleSubjects">
 
      <div class="col-12" >
      

        <program-module-subjects-table     :student-evaluations="studentEvaluations" :program-module="programModule"></program-module-subjects-table>
      </div>
 

    </div>
  </div>
  <div  v-if="minor">
  <div class="row mt-2 mb-md-4 mb-lg-0" >
  
  <div class="col">
    <h3 class="text-center">MINOR ({{minor.minor_name}})</h3>
  </div>
</div>


<div  v-for="modules of Object.keys(minor.modulesWithSubjects)">
<h2 class="text-center">{{modules}}</h2>
<div class="row align-items-center justify-content-center mt-3 pb-5 mx-xl-5 px-xl-5" >
<div class="col">           
    <div class="program  w-100 px-4">
     
        <table class="table table-blue-wide">
          <thead>
            <tr>
                <th>{{ $t('my-program.subject-code') }}</th>
                <th>{{ $t('my-program.subject') }}</th>
                <th class="text-center">{{ $t('my-program.ects') }}</th>
                <th class="text-center">{{ $t('my-program.semester') }}</th>
                <th class="text-center"  ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="subject of minor.modulesWithSubjects[modules]">
              <td :data-label="$t('my-program.subject-code')">{{ subject.subject_code }}</td>
              <td :data-label="$t('my-program.subject')">
                <a target="_blank" :href="'http://online.ug.edu.ge/sillabus_system/syllabus_print.php?syllabusID='+subject.syllabusID+'&lang=geo'">
                  {{ helpers.getLocalizedField(subject, 'subject') }}
                </a>
              </td>
              <td :data-label="$t('my-program.ects')" class="text-center">{{ subject.ECTS }}</td>
              <td :data-label="$t('my-program.semester')" class="text-center">{{ subject.semester }}</td>
              <td  class="text-center"  >{{subject.Eval }}</td>
            </tr>
          </tbody>
        </table>
    </div>
</div>
</div>
</div>
</div>
</div>
</template>

<script>
  import ProgramModuleSubjectsTable from './program-module-subjects-table';
  import SkCard from '../skeletons/sk-card';
  import MinorProgramResource from '../../api/minorProgramResource';
  import { mapGetters } from 'vuex';

const minorProgramResource = new MinorProgramResource()
  export default {
    name: 'program-module-subjects',
     data(){

      return{

          minor:null

      }


    },
    computed: {
      ...mapGetters({ 
        user: 'auth/user'
      })
    },
    components: { SkCard, ProgramModuleSubjectsTable },
    props: ['program-module-subjects', 'student-evaluations'],
     mounted(){

      minorProgramResource.getMinor().then(response=>{

         this.minor=response.data    
      
    

      })


    }
  };
</script>
<style lang="scss" scoped>
  .table-blue-wide td a {
    color: #557c84;
  }
</style>
