<template>
  <div class="program bg-light p-4 vh-50"  v-if="generalInfoArray && generalInfoArray.length">
    
      <!-- program header -->
      <div class="program-header w-100">

          <div class="program-body__row pb-4">
              <div class="row ">
                  <div class="col-md-5 col-lg-4"><span>{{$t('my-program.program')}}</span></div>
                  <div class="col-md-7 pl-md-0 pl-lg-3 col-lg-8"><span> {{ helpers.getLocalizedField(this.generalInfo.speciality, 'speciality')}}</span></div>
              </div>
          </div>

      </div>
      <!-- program body -->
      <div class="program-body body-striped ">
          <div class="program-body__row"  v-for="data of generalInfoArray">
              <div class="row" >
                  <div class="col-md-5 col-lg-4">{{ data.label }}:</div>
                  <div class="col-md-7 pl-md-0 pl-lg-3 col-lg-8 " v-html="data.value"></div>
              </div>
          </div>
      </div>
  </div>
  <sk-card v-else></sk-card>

</template>

<script>
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import SkCard from '../skeletons/sk-card';

  export default {
    name: 'general-info',
    components: { SkCard },
    computed: {
      ...mapGetters({
        generalInfo: 'program/generalInfo',
        user: 'auth/user'
      })
    },

    data() {
      return {
        generalInfoArray: []
      }
    },

    mounted() {

   
      this.$store.dispatch('program/loadGeneralInfo', {
        programID: this.user.programID,
        concentrationID:this.user.concentration
      }).then(() => {
        this.reformatGeneralInfo();
        this.$emit('program-info-loaded', this.generalInfo)
      });
    },

    methods: {
      renderOutcomes(outcomes) {
        let html = '';

        for(let outcome of outcomes) {
          html += '<strong>'+this.helpers.getLocalizedField(outcome.type, 'type')+'</strong>';
          html += '<p>'+this.helpers.getLocalizedField(outcome, 'outcomes')+'</p>';
        }

        return html;
      },

      reformatGeneralInfo() {
        this.generalInfoArray = [
          {
            label: this.$t('my-program.program'),
            value: '<strong>'+this.helpers.getLocalizedField(this.generalInfo.speciality, 'speciality')+'</strong>'
          },
          {
            label: this.$t('my-program.study-level'),
            value: this.helpers.getLocalizedField(this.generalInfo.study_level, 'level')
          },
          {
            label: this.generalInfo.leaders.length > 1 ? this.$t('my-program.leaders') : this.$t('my-program.leader'),
            value: this.generalInfo.leaders.map(o => this.helpers.getLocalizedField(o, 'fname') + ' ' + this.helpers.getLocalizedField(o, 'lname')).join(', ')
          },
          {
            label: this.$t('my-program.study-language'),
            value: this.helpers.getLocalizedField(this.generalInfo.language, 'language')
          },
          {
            label: this.$t('my-program.qualification'),
            value: this.helpers.getLocalizedField(this.generalInfo, 'program_qualification')
          },
          {
            label: this.$t('my-program.program-ects'),
            value: this.generalInfo.ECTS
          },
          {
            label: this.$t('my-program.permission'),
            value: this.helpers.getLocalizedField(this.generalInfo, 'program_permission')
          },
          {
            label: this.$t('my-program.goals'),
            value: this.helpers.getLocalizedField(this.generalInfo, 'goals')
          },
          {
            label: this.$t('my-program.methods-attaining'),
            value: this.helpers.getLocalizedField(this.generalInfo, 'methods_attaining')
          },
          {
            label: this.$t('my-program.study-result'),
            value: this.renderOutcomes(this.generalInfo.outcomes)
          },
          {
            label: this.$t('my-program.program-approve-date'),
            value: moment(this.generalInfo.report_date).format('YYYY-MM-DD')
          },
          {
            label: this.$t('my-program.program-approve-number'),
            value: this.generalInfo.report_number
          },
          {
            label: this.$t('my-program.program-update-date'),
            value: moment(this.generalInfo.report_update_date).format('YYYY-MM-DD')
          },
          {
            label: this.$t('my-program.program-update-number'),
            value: this.generalInfo.report_update_number
          },
          {
            label: this.$t('my-program.program-details'),
            value: this.helpers.getLocalizedField(this.generalInfo, 'program_details')
          },
          {
            label: this.$t('my-program.learning-pecularities'),
            value: this.helpers.getLocalizedField(this.generalInfo, 'learning_pecularities')
          },
        ]
      }
    },
  };
</script>
